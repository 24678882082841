import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import { Markdown } from "components/Markdown";
import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import famImg from "src/images/community/family-matters-fam.jpg";
import famImg2 from "src/images/community/family-matters-fam2.jpg";
import famImg3 from "src/images/community/family-matters-fam3.jpg";

export default function Page() {
  return (
    <BlogLayout slug="family-matters-is-a-place-of-possibility">
      <Paragraph component="div">
        <Box
          component="img"
          src={famImg}
          sx={{ width: "50%", float: "left" }}
          mr={3}
          mb={3}
        />
        <Markdown>
          EOCP's Family Matters shelter is **a space that creates beautiful
          possibility**: Since its door opened in June 2020, families with
          children arrive onsite ready to make a change in their lives, and
          cross the threshold out of homelessness. The Family Matters case
          management team works closely with the 20+ families in residence to
          identify and transition them into a permanent housing placement. The
          families also have access to mental health care, parenting workshops,
          youth enrichment activities and academic support, plus a personalized
          job coaching so they can build their ability to self-sustain with
          resilience, through major challenges.
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        LaQuoia Palmore, Case Manager at Family Matters, shared,
        <Markdown>
          {`"Many clients come to Family Matters ready to do whatever it takes to
get into permanent housing, but some of them are scared, because they
might not have ever lived on their own. They aren't sure about the
responsibility of paying rent. But that's what we are here for: To
help residents take a big step in their lives. Working to secure
housing is always a mutual effort. **We stick by people to make sure
they are getting access to the care they need immediately, but we also
work to make sure they have concrete and long-term goals** for
sustainably living outside of emergency housing, in a stable home with
their children.

To **keep these goals front of mind** for every resident who comes in
the door, I am excited to create a housing "vision board." It's a
place where other residents can get excited about their own journey by
seeing photos of former residents with their new house keys, and to
congratulate the huge accomplishments of families who graduate from
the program. These small, visible things help remind us all: **It's
possible.** Clients first arrive and they say, "Are you sure we'll
find housing?" And I reassure them: "If you're pushing yourself, and
you're excited about working together with us as a team, you will be
housed." I want every Family Matters resident to picture themselves on
that vision board, and get motivated about what it takes to find
housing as soon as possible."`}
        </Markdown>
      </Blockquote>
      <Paragraph component="div">
        <Box
          display={{ xs: "none" }}
          component="img"
          src={famImg2}
          sx={{ width: "100%" }}
          mb={3}
        />
        <Markdown>
          {`### *Family Matters' new Shelter Manager, Emmanuel Carrie, joined EOCP in March of this year, and brings a commitment to mentorship while providing care for families --- as well as for his growing team. He said:*

"For the Family Matters team, we always have the opportunity to lead and mentor through modeling the way with our residents. **Things become possible when we grow our own confidence, and set goals for our own growth.**When we first support ourselves and learn about who we are within, we can better care for others. We can learn how to create calm direction, and to work smarter, not harder. This is true for myself individually, for each of our dedicated staff as they show up for our families, and for our residents, as they build the confidence to thrive and care for their children."`}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        <Markdown>{`### ***The energy of possibility at Family Matters is fueling a moment of hope and new beginnings.** EOCP's Interim Executive Director, Shari P. Wooldridge, noted:*

"The effects of the COVID pandemic are still being felt, and more and more families in Oakland are still sleeping in their cars, or in encampments. EOCP steps up to address those challenges because **we have the space, the people, and the energy to take it on** --- and to provide the critical care that those folks need to step into a new reality for themselves and their young ones. We have been, and will keep on, showing up for families in need in our community."`}</Markdown>
      </Blockquote>
      <Paragraph component="div">
        <Box
          component="img"
          src={famImg3}
          sx={{ width: "40%", float: "left" }}
          mr={3}
          mb={3}
        />
        <Markdown>
          The last few years of the pandemic have made an impact on moving
          families into permanent housing. Of the families that came through the
          doors of Family Matters 51% were permanently housed. The adults in the
          families worked to achieve employment which translated into 48%
          achieving their goals of obtaining employment or increasing their
          monthly income.
        </Markdown>
      </Paragraph>
    </BlogLayout>
  );
}
